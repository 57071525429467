import { useState } from "react";
import "./App.css";

import InputItemData from "./components/InputItemData";
import DisplayData from "./components/DisplayData";

function App() {
  const [filteredData, setFilteredData] = useState();
  const [productType, setProductType] = useState();

  const handleFilterData = (data, type) => {
    setFilteredData(data);
    setProductType(type);
  };

  return (
    <>
      <InputItemData onFilteredData={handleFilterData} />
      <DisplayData filteredData={filteredData} productType={productType} />
    </>
  );
}

export default App;
