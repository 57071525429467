import React from "react";
import GalvanisedTable from "./GalvanisedTable";
import BlackPipesTable from "./BlackPipesTable";

export default function DisplayData({ filteredData, productType }) {
  return (
    <>
      <div className="mx-4 md:mx-8 border-4 rounded-xl mb-20">
        {productType === "getItems" && (
          <GalvanisedTable filteredData={filteredData} />
        )}
        {productType === "getBlackpipes" && (
          <BlackPipesTable filteredData={filteredData} />
        )}
      </div>
    </>
  );
}
