import React, { useEffect, useState } from "react";
import axios from "axios";
import { IoIosSearch } from "react-icons/io";

export default function InputItemData({ onFilteredData, onProductType }) {
  const [fetchData, setFetchData] = useState();
  const [nb, setNb] = useState("");
  const [series, setSeries] = useState("");
  const [thickness_mm, setThickness_mm] = useState("");
  const [outsideDiameter_mm, setOutsideDiameter_mm] = useState("");
  const [productType, setProductType] = useState("");

  useEffect(() => {
    try {
      // axios.get(`http://localhost:8000/${productType}`).then((res) => {
      axios
        .get(`https://pipesapi-production.up.railway.app/${productType}`)
        .then((res) => {
          setFetchData(res.data);
        });
    } catch (e) {
      console.log(e);
    }
  }, [productType]);

  const formHandler = (e) => {
    e.preventDefault();
    if (productType === "getItems") {
      const filteredData = fetchData.filter((data) => {
        return (
          (nb === "" || data.nb.toString() === nb) &&
          (series === "" ||
            data.series.toLowerCase() === series.toLowerCase()) &&
          (thickness_mm === "" ||
            (parseFloat(data["thickness-mm"]) >=
              Math.floor(parseFloat(thickness_mm)) &&
              parseFloat(data["thickness-mm"]) <
                Math.floor(parseFloat(thickness_mm)) + 1)) &&
          (outsideDiameter_mm === "" ||
            (parseFloat(data["outside-diameter-mm"]) >=
              parseFloat(outsideDiameter_mm) - 5 &&
              parseFloat(data["outside-diameter-mm"]) <
                parseFloat(outsideDiameter_mm) + 5))
        );
      });

      onFilteredData(filteredData, productType);
    } else if (productType === "getBlackpipes") {
      const filteredData = fetchData.filter((data) => {
        return (
          (nb === "" || data.nb.toString() === nb) &&
          (series === "" ||
            data.series.toLowerCase() === series.toLowerCase()) &&
          (thickness_mm === "" ||
            (parseFloat(data["thickness-mm"]) >=
              Math.floor(parseFloat(thickness_mm)) &&
              parseFloat(data["thickness-mm"]) <
                Math.floor(parseFloat(thickness_mm)) + 1)) &&
          (outsideDiameter_mm === "" ||
            (parseFloat(data["outside-diameter-mm-max"]) >=
              parseFloat(outsideDiameter_mm) - 5 &&
              parseFloat(data["outside-diameter-mm-max"]) <
                parseFloat(outsideDiameter_mm) + 5))
        );
      });

      onFilteredData(filteredData, productType);
    } else {
      alert("Please select Product type");
    }
  };
  return (
    <>
      <div className="pt-10 px-8 flex gap-3 w-full">
        <label className="py-1 hidden md:flex">Product</label>
        <select
          className="rounded-full py-1 px-2 bg-gray-200"
          value={productType}
          onChange={(e) => setProductType(e.target.value)}
        >
          <option value="">Select the type of product</option>
          <option value="getItems">Galvanised Pipes</option>
          <option value="getBlackpipes">Black Pipes</option>
        </select>
      </div>
      <form
        onSubmit={formHandler}
        className="w-full py-10 px-8 grid grid-cols-1 md:grid-cols-3  gap-4"
      >
        <div className="flex gap-3">
          <label className="p-1 w-20 hidden md:flex">NB</label>
          <input
            type="number"
            className="rounded-full py-1 px-2 bg-gray-200 w-full"
            value={nb}
            placeholder="Enter NB"
            onChange={(e) => setNb(e.target.value)}
          />
        </div>
        <div className="flex">
          <label className="p-1 w-20 hidden md:flex">Inch</label>
          <select
            className="rounded-full py-1 px-2 bg-gray-200 w-full"
            value={nb}
            onChange={(e) => setNb(e.target.value)}
          >
            <option value="">Select inches</option>
            <option value="15">1/2</option>
            <option value="20">3/4</option>
            <option value="25">1</option>
            <option value="32">1 1/4</option>
            <option value="40">1 1/2</option>
            <option value="50">2</option>
            <option value="65">2 1/2</option>
            <option value="80">3</option>
            <option value="100">4</option>
            <option value="125">5</option>
            <option value="150">6</option>
            <option value="200">8</option>
          </select>
        </div>
        <div className="flex">
          <label className="p-1 w-20 hidden md:flex">OD</label>
          <input
            type="number"
            placeholder="Enter OD"
            className="rounded-full py-1 px-2 bg-gray-200 w-full"
            value={outsideDiameter_mm}
            onChange={(e) => setOutsideDiameter_mm(e.target.value)}
          />
        </div>
        <div className="flex">
          <label className="p-1 w-20 hidden md:flex">Series</label>
          <select
            className="rounded-full py-1 px-2 bg-gray-200 w-full"
            value={series}
            onChange={(e) => setSeries(e.target.value)}
          >
            <option value="">Select the series</option>
            <option>Light</option>
            <option>Medium</option>
            <option>Heavy</option>
          </select>
        </div>
        <div className="flex">
          <label className="p-1 w-20 hidden md:flex">Thickness</label>
          <input
            type="number"
            className="rounded-full py-1 px-2 bg-gray-200 w-full"
            value={thickness_mm}
            placeholder="Enter Thickness"
            onChange={(e) => setThickness_mm(e.target.value)}
          />
        </div>
        <div className="flex">
          <button
            type="submit"
            className="text-white bg-sky-600 rounded-full py-1 px-4 hover:bg-sky-900 ease-in duration-300 w-28 flex gap-2"
          >
            <span className="py-1 ">
              <IoIosSearch />
            </span>
            <span>Search</span>
          </button>
        </div>
      </form>
    </>
  );
}
