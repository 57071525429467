import React from "react";

export default function GalvanisedTable({ filteredData }) {
  return (
    <>
      <div className="overflow-auto rounded-lg">
        <table className="w-full">
          <thead className="">
            <tr className="text-[14px]  bg-[#f5f6fa] p-3 text-gray-700">
              <th className="px-3 py-3 w-28 font-medium text-left">ID</th>
              <th className="px-3 py-3 w-28 font-medium text-left">NB</th>
              <th className="px-3 py-3 w-28 uppercase  font-medium text-left">
                outside diameter mm
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                plain end kg m
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                plain end mtrs tone
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                screwed socketed kg m
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                screwed socketed mtrs tone
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                series
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                sockets length mm
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                sockets od in mm
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                thickness mm
              </th>
              <th className="px-3 py-3 w-28 uppercase font-medium text-left">
                thickness swg
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData &&
              filteredData.map((data, index) => (
                <tr key={index} className="border-b-2 border-[#f5f6fa]">
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data.id}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data.nb}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["outside-diameter-mm"] ||
                      data["outside-diameter-mm-max"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["plain-end-kg-m"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["plain-end-mtrs-tone"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["screwed-socketed-kg-m"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["screwed-socketed-mtrs-tone"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data.series}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["sockets-length-mm"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["sockets-od-in-mm"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600 w-28">
                    {data["thickness-mm"]}
                  </td>
                  <td className="px-3 py-2 text-[14px] font-medium text-gray-600">
                    {data["thickness-swg"]}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
